<template>
  <div>
    <div class="row m-0">
      <div class="col-lg-5 d-none d-lg-block bg-img-login"></div>

      <div class="col-lg-7">
        <div class="container h-100vh d-flex flex-column justify-content-between">
          <div class="pt-lg-2 mt-4">
            <div class="d-flex fs-27 fw-700">
              <svg
                class="mr-1"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.91091 30.1988L2.52089 22.8088C-0.840297 19.4476 -0.840297 13.9951 2.52089 10.6306L9.91091 3.24062C13.2721 -0.12057 18.7246 -0.12057 22.0891 3.24062L29.4791 10.6306C32.8403 13.9918 32.8403 19.4444 29.4791 22.8088L22.0891 30.1988C18.7246 33.56 13.2754 33.56 9.91091 30.1988Z"
                  fill="#20409B"
                />
                <path
                  d="M16.1889 10.7322C16.4802 10.706 16.7584 10.6537 17.0136 10.5719C17.4686 10.4475 17.8678 10.2249 18.1951 9.93038C18.2409 9.89111 18.2868 9.84529 18.3326 9.80274L18.349 9.78638C18.9512 9.18418 19.2817 8.33325 19.2326 7.3514C19.1966 6.61174 18.9282 5.95391 18.4995 5.44335C18.3817 5.29935 18.2508 5.16843 18.1068 5.05061C17.5929 4.6186 16.9351 4.3535 16.1954 4.31422C15.2594 4.2684 14.451 4.56296 13.8554 5.10952C13.8226 5.13897 13.7932 5.16516 13.7637 5.19461C13.3874 5.5579 13.1092 6.02264 12.9652 6.56593C12.9619 6.58229 12.9553 6.59865 12.9521 6.61502C12.8866 6.84739 12.8408 7.09612 12.8179 7.35468C12.8179 7.3874 12.8113 7.42013 12.8081 7.44958C12.4579 11.1806 10.5433 13.1247 6.84499 13.5076C6.7959 13.5141 6.74681 13.5174 6.69772 13.5239C4.81912 13.6941 3.5918 14.9672 3.60162 16.7378C3.60817 17.6542 3.95508 18.4397 4.55074 19.0059C5.11694 19.6016 5.90242 19.9452 6.81553 19.9518C8.58613 19.9648 9.85926 18.7375 10.0294 16.8557C10.0327 16.8033 10.0393 16.7575 10.0458 16.7084C10.4255 13.0068 12.3728 11.0922 16.1038 10.742C16.1267 10.7388 16.1594 10.7355 16.1889 10.7322Z"
                  fill="white"
                />
                <path
                  d="M25.3608 19.906C25.6521 19.8799 25.9302 19.8275 26.1855 19.7457C26.6404 19.6213 27.0397 19.3988 27.367 19.1042C27.4128 19.0649 27.4586 19.0191 27.5045 18.9766L27.5208 18.9602C28.123 18.358 28.4536 17.5071 28.4045 16.5252C28.3685 15.7856 28.1001 15.1277 27.6714 14.6172C27.5536 14.4732 27.4226 14.3423 27.2786 14.2244C26.7648 13.7924 26.107 13.5273 25.3673 13.4881C24.4313 13.4422 23.6229 13.7368 23.0273 14.2833C22.9945 14.3128 22.9651 14.339 22.9356 14.3684C22.5592 14.7317 22.281 15.1965 22.137 15.7398C22.1338 15.7561 22.1272 15.7725 22.1239 15.7888C22.0585 16.0212 22.0127 16.2699 21.9898 16.5285C21.9898 16.5612 21.9832 16.594 21.9799 16.6234C21.6298 20.3544 19.7152 22.2985 16.0169 22.6814C15.9678 22.6879 15.9187 22.6912 15.8696 22.6978C13.991 22.868 12.7637 24.1411 12.7735 25.9117C12.78 26.8281 13.127 27.6135 13.7226 28.1797C14.2888 28.7754 15.0743 29.119 15.9874 29.1256C17.758 29.1387 19.0311 27.9114 19.2013 26.0295C19.2046 25.9771 19.2111 25.9313 19.2177 25.8822C19.5973 22.1807 21.5447 20.2661 25.2757 19.9159C25.2986 19.9126 25.328 19.9093 25.3608 19.906Z"
                  fill="white"
                />
                <path
                  d="M16.0116 19.9489C17.801 19.9489 19.2517 18.4983 19.2517 16.7088C19.2517 14.9194 17.801 13.4688 16.0116 13.4688C14.2221 13.4688 12.7715 14.9194 12.7715 16.7088C12.7715 18.4983 14.2221 19.9489 16.0116 19.9489Z"
                  fill="white"
                />
              </svg>
              <p class="txt-pri">HODO</p>
            </div>
            <div class="fs-14 fw-500 txt-black mb-3">
              <p>Cộng tác trên nền tảng Bảo mật đạt chuẩn HIPAA</p>
            </div>
            <div class="w-100-441px">
              <form autocomplete="off">
                <div class="txt-black fs-32 fw-600">
                  <p>Thông tin tài khoản</p>
                </div>
                <div class>
                  <div class="mb-3">
                    <input
                      v-model="form.name"
                      placeholder="Họ và tên"
                      autocomplete="one-time-code"
                      class="form-control form-input fs-16 fw-500"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.name || " " }}</p>
                  </div>
                  <div class="mb-3">
                    <input
                      v-model="form.phone"
                      autocomplete="one-time-code"
                      placeholder="Số điện thoại"
                      class="form-control form-input fs-16 fw-500"
                    />
                  </div>
                  <div class="mb-3" v-if="!has_pass">
                    <input
                      v-model="form.password"
                      placeholder="Nhập mật khẩu"
                      type="password"
                      class="form-control form-input fs-16 fw-500"
                      name="password"
                      autocomplete="one-time-code"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.password || " " }}</p>
                  </div>
                  <div class="mb-2" v-if="!has_pass">
                    <input
                      v-model="form.repass"
                      placeholder="Nhập lại mật khẩu"
                      type="password"
                      class="form-control form-input fs-16 fw-500"
                      autocomplete="one-time-code"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.repass || " " }}</p>
                  </div>

                  <div
                    class="form-footer mb-3"
                    :set="[
                      (passed1 = checkLengthPw()),
                      (passed2 = checkText()),
                      (passed3 = checkNumber()),
                      (passed4 = this.form.password === this.form.repass),
                    ]"
                  >
                    <div
                      class="fs-14 fw-400 txt-msg mb-12 d-flex align-items-center"
                      v-if="!has_pass"
                    >
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed1"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed1"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-14 fw-400 mb-0"
                        :class="
                          passed1
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >Mật khẩu gồm 6-12 ký tự</p>
                    </div>

                    <div
                      class="fs-14 fw-400 txt-msg mb-12 d-flex align-items-center"
                      v-if="!has_pass"
                    >
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed2"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed2"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-14 fw-400 mb-0"
                        :class="
                          passed2
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >Có chữ cái thường (a-z)</p>
                    </div>

                    <div
                      class="fs-14 fw-400 txt-msg mb-12 d-flex align-items-center"
                      v-if="!has_pass"
                    >
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed3"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed3"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-14 fw-400 mb-0"
                        :class="
                          passed3
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >
                        Có ít nhất một chữ số (0-9) và ký tự đặc biệt (@ # $ _ .
                        - & *)
                      </p>
                    </div>

                    <div
                      class="fs-14 fw-400 txt-msg mb-12 d-flex align-items-center"
                      v-if="form.repass && !has_pass"
                    >
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed4"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed4"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-14 fw-400 mb-0"
                        :class="
                          passed4
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >Mật khẩu trùng khớp</p>
                    </div>
                    <button
                      type="button"
                      @click="onSignUp()"
                      class="btn bg-pri form-btn w-100 fs-16 fw-600"
                      :disabled="
                        !form.name ||
                        (!has_pass &&
                          (!form.password ||
                            !form.repass ||
                            !passed1 ||
                            !passed2 ||
                            !passed3 ||
                            !passed4)) ||
                        loading
                      "
                    >
                      Xác nhận
                      <span class="spinner-border ml-2" role="status" v-show="loading">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="w-100-441px">
            <p class="fs-13 fw-500">
              Bằng cách đăng nhập/ đăng ký vào trang web này, bạn đã đọc và đồng
              ý với
              <a
                class="txt-pri"
                href="javascript:;"
                @click="goToCond"
              >Điều khoản dịch vụ</a>cũng như
              <a
                class="txt-pri"
                href="javascript:;"
                @click="goToTerm"
              >Chính sách quyền riêng tư</a>
              của HODO
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'Infomation',
  components: {},
  computed: {
    thisDoctor () {
      let user = appUtils.getLocalUser()
      if (user.doctor) {
        return user
      }
    },
    isAdmin () {
      const user = appUtils.getLocalUser()
      if (user) {
        return user.roles.includes('admin')
      }
      return false
    }
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
        repass: '',
        name: '',
        phone: '',
        username: ''
      },
      error: {
        email: '',
        password: '',
        repass: '',
        name: '',
        phone: ''
      },
      has_pass: false,
      loading: false
    }
  },
  mounted () {
    if (!this.$user) {
      this.onLogin()
    } else {
      this.checkHasPw()
      this.form.email = this.$user.email
      this.form.name = this.$user.name
      this.form.phone = this.$user?.doctor?.phone
    }
  },
  methods: {
    async onSignUp () {
      let self = this
      if (!self.form.name) {
        self.error.name = 'Vui lòng nhập họ và tên!'
        return false
      } else if (!this.has_pass) {
        if (!self.form.password) {
          self.error.password = 'Vui lòng nhập mật khẩu!'
          return false
        } else if (!self.form.password) {
          self.error.password =
            'Mật khẩu của bạn phải có 6-12 ký tự bao gồm chữ và số!'
          return false
        } else if (!self.form.repass) {
          self.error.repass = 'Vui lòng nhập lại mật khẩu!'
          return false
        } else if (self.form.repass !== self.form.password) {
          self.error.repass = 'Nhập lại mật khẩu không đúng!'
          return false
        }
      }
      let params = {
        name: self.form.name,
        phone: self.form.phone,
        type: this.$user?.doctor?.type
      }
      var formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key] || '')
      }
      try {
        this.loading = true
        let path = '/'
        await self.$rf.getRequest('DoctorRequest').updateDoctorMe(formData)
        if (!this.has_pass) {
          await this.changePassword()
        }

        await self.$rf
          .getRequest('AuthRequest')
          .me()
          .then(resp => {
            if (resp) {
              appUtils.setLocalUser(resp.data)
              if (self.$route.query.redirect) {
                path = self.$route.query.redirect
              }
              self.$toast.open({
                message: 'Cập nhật thành công',
                type: 'success'
              })
            }
          })
        this.$router.push({ path: path })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async changePassword () {
      let self = this
      self.error.password = ''
      self.error.repass = ''
      var param = {
        password: self.form.password,
        password_confirmation: self.form.repass
      }
      try {
        let rep = await self.$rf
          .getRequest('AuthRequest')
          .postPasswordAgain(param)
          .catch(() => {})
        return rep
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    onLogin () {
      this.$router.push({ name: 'LoginPage' })
    },
    goToCond () {
      window.open('https://hodo.com.vn/#/dieu-khoan')
    },
    goToTerm () {
      window.open('https://hodo.com.vn/#/chinh-sach')
    },
    checkLengthPw () {
      return this.form.password && this.form.password.length > 6
    },
    checkText () {
      return /[a-z]/g.test(this.form.password)
    },
    checkNumber () {
      return (
        /[@#$_.\-&*]/g.test(this.form.password) &&
        /[0-9]/g.test(this.form.password)
      )
    },
    async checkHasPw () {
      let self = this
      await this.$rf
        .getRequest('AuthRequest')
        .checkHasPw()
        .then(r => {
          self.has_pass = r.data
        })
    }
  }
}
</script>
<style scoped>
.mb-12 {
  margin-bottom: 12px;
}
.form-input {
  color: #1d2939;
  border: 0.1px solid #98a2b3;
  padding: 13px;
  border-radius: 12px;
}
.form-btn {
  color: white;
  border-radius: 12px;
  padding: 17px;
}
.form-btn:disabled {
  background-color: #667085;
}
.txt-msg {
  color: #98a2b3;
}
.txt-success {
  color: #027a48;
}
.bg-img-login {
  height: 100vh;
  background-image: url("../../../public/assets/images/login/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.w-100-678px {
  width: 100%;
  max-width: 678px;
  margin: 0 auto;
}
.w-100-441px {
  width: 100%;
  max-width: 441px;
  margin: 0 auto;
}
.h-100vh {
  height: 100vh;
}
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>