<template>
  <div>
    <div class="row m-0 overflow-hidden">
      <div class="col-lg-4 d-none d-lg-block bg-img-login"></div>

      <div class="col-lg-8 p-0 y100-auto">
        <div class="container sign-form-wraper">
          <div class="pt-lg-2 mt-4">
            <div class="d-flex fs-27 fw-700">
              <svg
                class="mr-1"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.91091 30.1988L2.52089 22.8088C-0.840297 19.4476 -0.840297 13.9951 2.52089 10.6306L9.91091 3.24062C13.2721 -0.12057 18.7246 -0.12057 22.0891 3.24062L29.4791 10.6306C32.8403 13.9918 32.8403 19.4444 29.4791 22.8088L22.0891 30.1988C18.7246 33.56 13.2754 33.56 9.91091 30.1988Z"
                  fill="#20409B"
                />
                <path
                  d="M16.1889 10.7322C16.4802 10.706 16.7584 10.6537 17.0136 10.5719C17.4686 10.4475 17.8678 10.2249 18.1951 9.93038C18.2409 9.89111 18.2868 9.84529 18.3326 9.80274L18.349 9.78638C18.9512 9.18418 19.2817 8.33325 19.2326 7.3514C19.1966 6.61174 18.9282 5.95391 18.4995 5.44335C18.3817 5.29935 18.2508 5.16843 18.1068 5.05061C17.5929 4.6186 16.9351 4.3535 16.1954 4.31422C15.2594 4.2684 14.451 4.56296 13.8554 5.10952C13.8226 5.13897 13.7932 5.16516 13.7637 5.19461C13.3874 5.5579 13.1092 6.02264 12.9652 6.56593C12.9619 6.58229 12.9553 6.59865 12.9521 6.61502C12.8866 6.84739 12.8408 7.09612 12.8179 7.35468C12.8179 7.3874 12.8113 7.42013 12.8081 7.44958C12.4579 11.1806 10.5433 13.1247 6.84499 13.5076C6.7959 13.5141 6.74681 13.5174 6.69772 13.5239C4.81912 13.6941 3.5918 14.9672 3.60162 16.7378C3.60817 17.6542 3.95508 18.4397 4.55074 19.0059C5.11694 19.6016 5.90242 19.9452 6.81553 19.9518C8.58613 19.9648 9.85926 18.7375 10.0294 16.8557C10.0327 16.8033 10.0393 16.7575 10.0458 16.7084C10.4255 13.0068 12.3728 11.0922 16.1038 10.742C16.1267 10.7388 16.1594 10.7355 16.1889 10.7322Z"
                  fill="white"
                />
                <path
                  d="M25.3608 19.906C25.6521 19.8799 25.9302 19.8275 26.1855 19.7457C26.6404 19.6213 27.0397 19.3988 27.367 19.1042C27.4128 19.0649 27.4586 19.0191 27.5045 18.9766L27.5208 18.9602C28.123 18.358 28.4536 17.5071 28.4045 16.5252C28.3685 15.7856 28.1001 15.1277 27.6714 14.6172C27.5536 14.4732 27.4226 14.3423 27.2786 14.2244C26.7648 13.7924 26.107 13.5273 25.3673 13.4881C24.4313 13.4422 23.6229 13.7368 23.0273 14.2833C22.9945 14.3128 22.9651 14.339 22.9356 14.3684C22.5592 14.7317 22.281 15.1965 22.137 15.7398C22.1338 15.7561 22.1272 15.7725 22.1239 15.7888C22.0585 16.0212 22.0127 16.2699 21.9898 16.5285C21.9898 16.5612 21.9832 16.594 21.9799 16.6234C21.6298 20.3544 19.7152 22.2985 16.0169 22.6814C15.9678 22.6879 15.9187 22.6912 15.8696 22.6978C13.991 22.868 12.7637 24.1411 12.7735 25.9117C12.78 26.8281 13.127 27.6135 13.7226 28.1797C14.2888 28.7754 15.0743 29.119 15.9874 29.1256C17.758 29.1387 19.0311 27.9114 19.2013 26.0295C19.2046 25.9771 19.2111 25.9313 19.2177 25.8822C19.5973 22.1807 21.5447 20.2661 25.2757 19.9159C25.2986 19.9126 25.328 19.9093 25.3608 19.906Z"
                  fill="white"
                />
                <path
                  d="M16.0116 19.9489C17.801 19.9489 19.2517 18.4983 19.2517 16.7088C19.2517 14.9194 17.801 13.4688 16.0116 13.4688C14.2221 13.4688 12.7715 14.9194 12.7715 16.7088C12.7715 18.4983 14.2221 19.9489 16.0116 19.9489Z"
                  fill="white"
                />
              </svg>
              <p class="txt-pri mb-0">HODO</p>
            </div>
            <div class="fs-14 fw-500 txt-black mb-3">
              <p>{{ $t("common.lbl_slogan") }}</p>
            </div>

            <div
              class="flex items-center text-center mb-3 border border-solid border-top-0 border-right-0 border-left-0 border-gray-300"
            >
              <div class="w-1/2 px-3">
                <span
                  class="py-1 px-3 cursor-default inline-block border-2 border-solid border-top-0 border-right-0 border-left-0"
                  :class="!signed ? 'font-bold border-blue-900' : 'border-transparent'"
                >1. {{ $t("sign_up.lbl_title_register") }}</span>
              </div>
              <div class="w-1/2 px-3">
                <span
                  class="py-1 px-3 cursor-default inline-block border-2 border-solid border-top-0 border-right-0 border-left-0 border-transparent"
                  :class="signed ? 'font-bold border-blue-900' : 'border-transparent'"
                >2. {{ $t("sign_up.lbl_title_verification_info") }}</span>
              </div>
            </div>

            <div v-if="signed">
              <div class="text-center py-5 max-w-screen-sm mx-auto">
                <svg
                  width="86"
                  height="86"
                  viewBox="0 0 86 86"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0457 70.3404H23.4428V42.6685L7.16797 30.4741V65.5095C7.16797 68.1829 9.37233 70.3404 12.0457 70.3404Z"
                    fill="#4385F4"
                  />
                  <path
                    d="M62.5625 70.3399H73.9595C76.6798 70.3399 78.8373 68.1355 78.8373 65.4621V30.4268L62.5625 42.6211V70.3399Z"
                    fill="#36A852"
                  />
                  <path
                    d="M62.5625 21.4689V42.6214L78.8373 30.427V23.9077C78.8373 17.8575 71.9428 14.4336 67.1119 18.0451L62.5625 21.4689Z"
                    fill="#F9BC15"
                  />
                  <path
                    d="M23.4395 42.6682V21.5156L42.9974 36.1958L62.5553 21.5156V42.6682L42.9974 57.3014L23.4395 42.6682Z"
                    fill="#EA4535"
                  />
                  <path
                    d="M7.16797 23.9066V30.4259L23.4428 42.6203V21.4677L18.8933 18.0439C14.0625 14.4325 7.16797 17.9032 7.16797 23.9066Z"
                    fill="#C52627"
                  />
                </svg>
                <p class="fs-56 fw-700 txt-grey-900">{{ $t("sign_up.lbl_verification_email") }}</p>
                <p
                  class="fs-20 fw-400 txt-grey-900 mb-5"
                  v-html="$t('sign_up.messages_info.message_sent_email', [`<span class='underline'>${form.email}</span>`])"
                />
                <p
                  class="fs-18 fw-500 txt-grey-700"
                >{{ $t("sign_up.messages_info.not_received_email") }}</p>
                <p
                  class="fs-18 fw-600 txt-pri cursor-pointer"
                  @click="onSendOtp()"
                  v-if="!time_out"
                >{{ $t("sign_up.messages_info.send_email_again") }}</p>
                <p class="fs-18" v-if="time_out">
                  <span class="fw-500 txt-grey-600">
                    {{
                    $t("sign_up.messages_info.send_email_again")
                    }}
                  </span>
                  <span class="fw-800 txt-grey-900">
                    00:{{
                    time_out && (time_out > 10 ? time_out : "0" + time_out)
                    }}
                  </span>
                </p>
              </div>
            </div>

            <div v-else class="w-100-441px">
              <form autocomplete="off">
                <div class="txt-black fs-32 fw-600">
                  <p>{{ $t("sign_up.lbl_title_register") }}</p>
                </div>
                <div class="flex justify-between items-center mb-3">
                  <div class="flex items-center">
                    <div>
                      <svg
                        class="mr-1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.41 8.64C21.41 8.64 21.41 8.64 21.41 8.59C20.7054 6.66623 19.4269 5.00529 17.7475 3.83187C16.0681 2.65845 14.0688 2.02917 12.02 2.02917C9.97126 2.02917 7.97195 2.65845 6.29252 3.83187C4.6131 5.00529 3.33461 6.66623 2.63001 8.59C2.63001 8.59 2.63001 8.59 2.63001 8.64C1.84314 10.8109 1.84314 13.1891 2.63001 15.36C2.63001 15.36 2.63001 15.36 2.63001 15.41C3.33461 17.3338 4.6131 18.9947 6.29252 20.1681C7.97195 21.3416 9.97126 21.9708 12.02 21.9708C14.0688 21.9708 16.0681 21.3416 17.7475 20.1681C19.4269 18.9947 20.7054 17.3338 21.41 15.41C21.41 15.41 21.41 15.41 21.41 15.36C22.1969 13.1891 22.1969 10.8109 21.41 8.64ZM4.26001 14C3.91324 12.6892 3.91324 11.3108 4.26001 10H6.12001C5.96004 11.3285 5.96004 12.6715 6.12001 14H4.26001ZM5.08001 16H6.48001C6.71473 16.8918 7.05022 17.7541 7.48001 18.57C6.49931 17.9019 5.67951 17.0241 5.08001 16ZM6.48001 8H5.08001C5.67088 6.97909 6.4802 6.10147 7.45001 5.43C7.03057 6.24725 6.70515 7.10942 6.48001 8ZM11 19.7C9.77178 18.7987 8.90916 17.4852 8.57001 16H11V19.7ZM11 14H8.14001C7.9534 12.6732 7.9534 11.3268 8.14001 10H11V14ZM11 8H8.57001C8.90916 6.51477 9.77178 5.20132 11 4.3V8ZM18.92 8H17.52C17.2853 7.10816 16.9498 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0909 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0909 17.4852 14.2282 18.7987 13 19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9798 17.7541 17.3153 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0868 11.3108 20.0868 12.6892 19.74 14Z"
                          fill="#41464B"
                        />
                      </svg>
                      <span class="fs-16 fw-600 mr-3 text-black">
                        {{
                        $t("common.choose_lang")
                        }}
                      </span>
                    </div>
                    <language />
                  </div>
                </div>
                <div class>
                  <div class="mb-3">
                    <input
                      v-model="form.name"
                      :placeholder="
                        $t('sign_up.input_text.first_and_last_name')
                      "
                      type="text"
                      class="form-control form-input fs-16 fw-500"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.name || " " }}</p>
                  </div>
                  <div class="mb-3">
                    <input
                      v-model="form.phone"
                      :placeholder="$t('sign_up.input_text.phone_number')"
                      type="text"
                      class="form-control form-input fs-16 fw-500"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.phone || " " }}</p>
                  </div>

                  <div class="mb-3">
                    <select v-model="form.type" class="form-control form-input">
                      <option :value="null">{{ $t("sign_up.input_text.doctor") }}</option>
                      <option :value="1">{{ $t("sign_up.input_text.medical_officer") }}</option>
                      <option :value="2">{{ $t("sign_up.input_text.specialist") }}</option>
                      <option :value="3">{{ $t("sign_up.input_text.nursing") }}</option>
                      <option :value="4">{{ $t("sign_up.input_text.other") }}</option>
                    </select>
                    <p
                      class="fs-12 fw-500 fst-italic txt-pri mt-3"
                    >{{ $t("sign_up.lbl_is_your_title") }}</p>
                  </div>

                  <div class="mb-3">
                    <input
                      v-model="form.email"
                      :placeholder="$t('sign_up.input_text.email')"
                      type="text"
                      class="form-control form-input fs-16 fw-500"
                      name="email"
                      autocomplete="off"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.email || " " }}</p>
                  </div>
                  <div class="mb-3">
                    <input
                      v-model="form.password"
                      :placeholder="$t('sign_up.input_text.password')"
                      type="password"
                      class="form-control form-input fs-16 fw-500"
                      name="password"
                      autocomplete="off"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.password || " " }}</p>
                  </div>
                  <div class="mb-2">
                    <input
                      v-model="form.repass"
                      :placeholder="$t('sign_up.input_text.re_pwd')"
                      type="password"
                      class="form-control form-input fs-16 fw-500"
                      autocomplete="off"
                    />
                    <p class="my-1 pl-3 fs-10 text-danger">{{ error.repass || " " }}</p>
                  </div>

                  <div
                    class="my-3"
                    :set="[
                      (passed1 = checkLengthPw()),
                      (passed2 = checkText()),
                      (passed3 = checkNumber()),
                      (passed4 = this.form.password === this.form.repass),
                    ]"
                  >
                    <div class="fs-12 fw-400 txt-msg mb-12 d-flex align-items-center">
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed1"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed1"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-12 fw-400 mb-0"
                        :class="
                          passed1
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >
                        {{
                        $t(
                        "sign_up.message_errors.password_consists_of_6_12_characters"
                        )
                        }}
                      </p>
                    </div>

                    <div class="fs-12 fw-400 txt-msg mb-12 d-flex align-items-center">
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed2"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed2"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-12 fw-400 mb-0"
                        :class="
                          passed2
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >
                        {{
                        $t(
                        "sign_up.message_errors.contains_lowercase_letters_a_z"
                        )
                        }}
                      </p>
                    </div>

                    <div class="fs-12 fw-400 txt-msg mb-12 d-flex align-items-center">
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed3"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed3"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-12 fw-400 mb-0"
                        :class="
                          passed3
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >
                        {{
                        $t(
                        "sign_up.message_errors.contains_at_least_one_digit"
                        )
                        }}
                      </p>
                    </div>

                    <div
                      class="fs-12 fw-400 txt-msg mb-12 d-flex align-items-center"
                      v-if="form.repass"
                    >
                      <div>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="!form.password"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                            fill="#98A2B3"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && passed4"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                            fill="#027A48"
                          />
                        </svg>
                        <svg
                          class="mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-if="form.password && !passed4"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.74 17L17 15.74L13.26 12L17 8.26L15.74 7L12 10.74L8.26 7L7 8.26L10.74 12L7 15.74L8.26 17L12 13.26L15.74 17Z"
                            fill="#B42318"
                          />
                        </svg>
                      </div>
                      <p
                        class="fs-12 fw-400 mb-0"
                        :class="
                          passed4
                            ? 'txt-success'
                            : form.password
                            ? 'text-danger'
                            : 'txt-msg'
                        "
                      >
                        {{
                        $t(
                        "sign_up.message_errors.confirmation_pwd_does_not_match"
                        )
                        }}
                      </p>
                    </div>

                    <button
                      type="button"
                      @click="onSignUp()"
                      class="btn bg-pri form-btn w-100 fs-16 fw-600"
                      :disabled="
                        !form.name ||
                        !form.email ||
                        !form.phone ||
                        !form.password ||
                        !form.repass ||
                        !passed1 ||
                        !passed2 ||
                        !passed3 ||
                        !passed4
                      "
                    >{{ $t("sign_up.lbl_title_register") }}</button>
                  </div>

                  <div class="flex justify-end">
                    <p class="fs-14 fw-500 mb-0">
                      {{ $t("sign_up.lbl_do_you_already_have_an_account") }}
                      <a
                        class="txt-pri"
                        href="javascript:;"
                        @click="onLogin()"
                      >{{ $t("sign_up.lbl_login_here") }}</a>
                    </p>
                  </div>
                </div>
              </form>
              <div
                class="fs-13 fw-500"
                v-html="
                $t('login.other_labels.lbl_term_and_policy', [
                  `<a class='txt-pri' href='#/dieu-khoan' target='_blank'>${$t(
                    'login.other_labels.lbl_term_of_service'
                  )}</a>`,
                  `<a class='txt-pri' href='#/chinh-sach' target='_blank'>${$t(
                    'login.other_labels.lbl_privacy_policy'
                  )}</a>`,
                ])
              "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { LANGUAGE_KEYS } from '../../lang'
import Language from '../../components/Common/Language.vue'

export default {
  name: 'SignUp',
  components: { Language },
  computed: {
    thisDoctor () {
      let user = appUtils.getLocalUser()
      if (user.doctor) {
        return user
      }
    },
    isAdmin () {
      const user = appUtils.getLocalUser()
      if (user) {
        return user.roles.includes('admin')
      }
      return false
    }
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
        repass: '',
        name: '',
        phone: '',
        username: '',
        type: null
      },
      error: {
        email: '',
        password: '',
        repass: '',
        name: '',
        phone: ''
      },
      loading: false,
      signed: false,
      time_out: 0,
      interval_resend: null,
      LANGUAGE_KEYS
    }
  },
  mounted () {
    if (appUtils.getLocalUser()) {
      this.onLogout()
    }
  },
  methods: {
    async onSendOtp () {
      let self = this
      if (!this.form.email) {
        alert(this.$t('sign_up.alerts.you_have_not_enter_email'))
        return
      }
      const params = {
        email: this.form.email
      }
      await this.$rf
        .getRequest('AuthRequest')
        .postResendEmail(params)
        .then(r => {
          self.time_out = 30
          self.interval_resend = setInterval(self.countDownTimeout, 1000)
        })
    },
    countDownTimeout () {
      this.time_out -= 1
      if (this.time_out === 0) {
        clearInterval(this.interval_resend)
      }
    },
    async onSignUp () {
      let self = this
      if (!self.form.name) {
        self.error.name = this.$t(
          'sign_up.message_errors.please_enter_your_name'
        )
        return false
      } else if (!self.form.email) {
        self.error.email = this.$t(
          'sign_up.message_errors.please_enter_your_email'
        )
        return false
      } else if (!self.form.password) {
        self.error.password = this.$t(
          'sign_up.message_errors.please_enter_your_pwd'
        )
        return false
      } else if (!self.form.password) {
        self.error.password = this.$t(
          'sign_up.message_errors.pwd_must_be_6_12_characters_including_letters_and_numbers'
        )
        return false
      } else if (!self.form.repass) {
        self.error.repass = this.$t(
          'sign_up.message_errors.please_enter_re_pwd'
        )
        return false
      } else if (self.form.repass !== self.form.password) {
        self.error.repass = this.$t(
          'sign_up.message_errors.re_enter_pwd_does_not_match'
        )
        return false
      } else if (!self.form.phone) {
        self.error.phone = this.$t(
          'sign_up.message_errors.please_enter_your_phone'
        )
        return false
      } else {
        try {
          self.loading = true
          self.form.username = self.form.email
          let resp = await self.$rf
            .getRequest('AuthRequest')
            .postDoctorRegister(self.form)
          if (resp) {
            self.signed = true
            self.time_out = 30
            self.interval_resend = setInterval(self.countDownTimeout, 1000)
          }
        } catch (e) {
          self.loading = false
        }
      }
    },
    onLogin () {
      this.$router.push({ name: 'LoginPage' })
    },
    goToCond () {
      window.open('https://hodo.com.vn/#/dieu-khoan')
    },
    goToTerm () {
      window.open('https://hodo.com.vn/#/chinh-sach')
    },
    checkLengthPw () {
      return this.form.password && this.form.password.length > 6
    },
    checkText () {
      return /[a-z]/g.test(this.form.password)
    },
    checkNumber () {
      return (
        /[@#$_.\-&*]/g.test(this.form.password) &&
        /[0-9]/g.test(this.form.password)
      )
    }
  }
}
</script>
<style scoped>
.overflow-hidden {
  overflow: hidden;
}
.y100-auto {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.sign-form-wraper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
::-webkit-scrollbar {
  width: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.form-input {
  color: #1d2939;
  border: 0.1px solid #98a2b3;
  padding: 8px;
  border-radius: 12px;
}
.form-btn {
  color: white;
  border-radius: 12px;
  padding: 12px;
}
.form-btn:disabled {
  background-color: #667085;
}
.txt-msg {
  color: #98a2b3;
}
.txt-success {
  color: #027a48;
}
.bg-img-login {
  height: 100vh;
  background-image: url("../../../public/assets/images/login/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.w-100-678px {
  width: 100%;
  max-width: 678px;
  margin: 0 auto;
}
.w-100-441px {
  width: 100%;
  max-width: 441px;
  margin: 0 auto;
}
.h-100vh {
  height: 100vh;
}
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
