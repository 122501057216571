<template>
  <div>
    <div class="row m-0 overflow-hidden">
      <div class="col-lg-4 d-none d-lg-block bg-img-login"></div>

      <div class="col-lg-8 p-0 y100-auto">
        <div class="container sign-form-wraper">
          <div class="pt-lg-2 mt-4">
            <div class="d-flex fs-27 fw-700">
              <svg
                class="mr-1"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.91091 30.1988L2.52089 22.8088C-0.840297 19.4476 -0.840297 13.9951 2.52089 10.6306L9.91091 3.24062C13.2721 -0.12057 18.7246 -0.12057 22.0891 3.24062L29.4791 10.6306C32.8403 13.9918 32.8403 19.4444 29.4791 22.8088L22.0891 30.1988C18.7246 33.56 13.2754 33.56 9.91091 30.1988Z"
                  fill="#20409B"
                />
                <path
                  d="M16.1889 10.7322C16.4802 10.706 16.7584 10.6537 17.0136 10.5719C17.4686 10.4475 17.8678 10.2249 18.1951 9.93038C18.2409 9.89111 18.2868 9.84529 18.3326 9.80274L18.349 9.78638C18.9512 9.18418 19.2817 8.33325 19.2326 7.3514C19.1966 6.61174 18.9282 5.95391 18.4995 5.44335C18.3817 5.29935 18.2508 5.16843 18.1068 5.05061C17.5929 4.6186 16.9351 4.3535 16.1954 4.31422C15.2594 4.2684 14.451 4.56296 13.8554 5.10952C13.8226 5.13897 13.7932 5.16516 13.7637 5.19461C13.3874 5.5579 13.1092 6.02264 12.9652 6.56593C12.9619 6.58229 12.9553 6.59865 12.9521 6.61502C12.8866 6.84739 12.8408 7.09612 12.8179 7.35468C12.8179 7.3874 12.8113 7.42013 12.8081 7.44958C12.4579 11.1806 10.5433 13.1247 6.84499 13.5076C6.7959 13.5141 6.74681 13.5174 6.69772 13.5239C4.81912 13.6941 3.5918 14.9672 3.60162 16.7378C3.60817 17.6542 3.95508 18.4397 4.55074 19.0059C5.11694 19.6016 5.90242 19.9452 6.81553 19.9518C8.58613 19.9648 9.85926 18.7375 10.0294 16.8557C10.0327 16.8033 10.0393 16.7575 10.0458 16.7084C10.4255 13.0068 12.3728 11.0922 16.1038 10.742C16.1267 10.7388 16.1594 10.7355 16.1889 10.7322Z"
                  fill="white"
                />
                <path
                  d="M25.3608 19.906C25.6521 19.8799 25.9302 19.8275 26.1855 19.7457C26.6404 19.6213 27.0397 19.3988 27.367 19.1042C27.4128 19.0649 27.4586 19.0191 27.5045 18.9766L27.5208 18.9602C28.123 18.358 28.4536 17.5071 28.4045 16.5252C28.3685 15.7856 28.1001 15.1277 27.6714 14.6172C27.5536 14.4732 27.4226 14.3423 27.2786 14.2244C26.7648 13.7924 26.107 13.5273 25.3673 13.4881C24.4313 13.4422 23.6229 13.7368 23.0273 14.2833C22.9945 14.3128 22.9651 14.339 22.9356 14.3684C22.5592 14.7317 22.281 15.1965 22.137 15.7398C22.1338 15.7561 22.1272 15.7725 22.1239 15.7888C22.0585 16.0212 22.0127 16.2699 21.9898 16.5285C21.9898 16.5612 21.9832 16.594 21.9799 16.6234C21.6298 20.3544 19.7152 22.2985 16.0169 22.6814C15.9678 22.6879 15.9187 22.6912 15.8696 22.6978C13.991 22.868 12.7637 24.1411 12.7735 25.9117C12.78 26.8281 13.127 27.6135 13.7226 28.1797C14.2888 28.7754 15.0743 29.119 15.9874 29.1256C17.758 29.1387 19.0311 27.9114 19.2013 26.0295C19.2046 25.9771 19.2111 25.9313 19.2177 25.8822C19.5973 22.1807 21.5447 20.2661 25.2757 19.9159C25.2986 19.9126 25.328 19.9093 25.3608 19.906Z"
                  fill="white"
                />
                <path
                  d="M16.0116 19.9489C17.801 19.9489 19.2517 18.4983 19.2517 16.7088C19.2517 14.9194 17.801 13.4688 16.0116 13.4688C14.2221 13.4688 12.7715 14.9194 12.7715 16.7088C12.7715 18.4983 14.2221 19.9489 16.0116 19.9489Z"
                  fill="white"
                />
              </svg>
              <p class="txt-pri mb-0">HODO</p>
            </div>
            <div class="fs-14 fw-500 txt-black mb-3">
              <p>{{ $t("common.lbl_slogan") }}</p>
            </div>

            <div
              class="text-center fs-32 fw-600 txt-black mb-4"
            >{{ $t('sign_up.lbl_register_for_a_business_account') }}</div>

            <div
              class="flex items-center text-center mb-3 border border-solid border-top-0 border-right-0 border-left-0 border-gray-300"
            >
              <div class="w-1/3 px-3">
                <span
                  class="py-1 px-3 cursor-default inline-block border-2 border-solid border-top-0 border-right-0 border-left-0"
                  :class="step === 1 ? 'font-bold border-blue-900' : 'border-transparent'"
                >1. {{ $t("sign_up.lbl_title_register") }}</span>
              </div>
              <div class="w-1/3 px-3">
                <span
                  class="py-1 px-3 cursor-default inline-block border-2 border-solid border-top-0 border-right-0 border-left-0"
                  :class="step === 2 ? 'font-bold border-blue-900' : 'border-transparent'"
                >2. {{ $t("sign_up.lbl_title_verification_info") }}</span>
              </div>
              <div class="w-1/3 px-3">
                <span
                  class="py-1 px-3 cursor-default inline-block border-2 border-solid border-top-0 border-right-0 border-left-0"
                  :class="step === 3 ? 'font-bold border-blue-900' : 'border-transparent'"
                >3. {{ $t("sign_up.lbl_title_organization_setup") }}</span>
              </div>
            </div>

            <!-- Step 1: Register -->
            <div v-if="step === 1" v-loading="isLoading">
              <div class="pt-4 text-center">
                <div class="fs-28 fw-600 txt-black">{{ $t('sign_up.lbl_first_enter_your_email') }}</div>
                <div class="text-lg">{{ $t('sign_up.lbl_suggest_email_address') }}</div>
              </div>
              <div class="w-100-441px py-4">
                <form
                  autocomplete="off"
                  novalidate="novalidate"
                  @submit.prevent="handleNextStepTwo"
                >
                  <div class="mb-3">
                    <label class="block mb-0.5 sr-only">{{ $t('sign_up.input_text.email') }}</label>
                    <div>
                      <input
                        v-model="formStep1.email"
                        :placeholder="
                        $t('sign_up.input_text.email')
                      "
                        type="email"
                        class="form-control form-input fs-16 fw-500 w-full"
                      />
                    </div>
                    <small
                      v-if="isErrorValid && !!errorFields.email"
                      class="text-warning"
                    >{{ errorFields.email }}</small>
                  </div>
                  <div class="mb-3">
                    <button
                      @click="handleNextStepTwo"
                      type="button"
                      class="btn bg-pri form-btn w-100 fs-16 fw-600"
                    >{{ $t("button.continue") }}</button>
                  </div>
                  <div class="flex justify-end mb-3">
                    <p class="fs-14 fw-500 mb-0">
                      {{ $t("sign_up.lbl_do_you_already_have_an_account") }}
                      <a
                        class="txt-pri"
                        href="javascript:;"
                        @click="onLogin()"
                      >{{ $t("sign_up.lbl_login_here") }}</a>
                    </p>
                  </div>
                </form>
                <div
                  class="fs-13 fw-500"
                  v-html="
                $t('login.other_labels.lbl_term_and_policy', [
                  `<a class='txt-pri' href='#/dieu-khoan' target='_blank'>${$t(
                    'login.other_labels.lbl_term_of_service'
                  )}</a>`,
                  `<a class='txt-pri' href='#/chinh-sach' target='_blank'>${$t(
                    'login.other_labels.lbl_privacy_policy'
                  )}</a>`,
                ])
              "
                />
              </div>
            </div>

            <!-- Step 2: Verification -->
            <div v-else-if="step === 2" v-loading="isLoading">
              <div class="text-center py-5 max-w-screen-sm mx-auto">
                <svg
                  width="86"
                  height="86"
                  viewBox="0 0 86 86"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0457 70.3404H23.4428V42.6685L7.16797 30.4741V65.5095C7.16797 68.1829 9.37233 70.3404 12.0457 70.3404Z"
                    fill="#4385F4"
                  />
                  <path
                    d="M62.5625 70.3399H73.9595C76.6798 70.3399 78.8373 68.1355 78.8373 65.4621V30.4268L62.5625 42.6211V70.3399Z"
                    fill="#36A852"
                  />
                  <path
                    d="M62.5625 21.4689V42.6214L78.8373 30.427V23.9077C78.8373 17.8575 71.9428 14.4336 67.1119 18.0451L62.5625 21.4689Z"
                    fill="#F9BC15"
                  />
                  <path
                    d="M23.4395 42.6682V21.5156L42.9974 36.1958L62.5553 21.5156V42.6682L42.9974 57.3014L23.4395 42.6682Z"
                    fill="#EA4535"
                  />
                  <path
                    d="M7.16797 23.9066V30.4259L23.4428 42.6203V21.4677L18.8933 18.0439C14.0625 14.4325 7.16797 17.9032 7.16797 23.9066Z"
                    fill="#C52627"
                  />
                </svg>
                <p class="fs-56 fw-700 txt-grey-900">{{ $t("sign_up.lbl_verification_email") }}</p>
                <p
                  class="fs-20 fw-400 txt-grey-900 mb-3"
                  v-html="$t('sign_up.messages_info.message_sent_otp_code', [`<span class='underline'>${formStep1.email}</span>`])"
                />
                <p
                  v-if="warningMsg"
                  class="py-2 px-3 text-xl rounded bg-yellow-800 bg-opacity-10 border border-yellow-800 text-yellow-800"
                >
                  <i class="el-icon-warning mr-1" />
                  <span>{{ warningMsg }}</span>
                </p>
                <p
                  v-if="otpCodeError"
                  class="py-2 px-3 text-xl rounded bg-red-800 bg-opacity-10 border border-red-800 text-red-800"
                >
                  <i class="el-icon-error mr-1" />
                  <span>{{ $t(`errors_code.${otpCodeError}`) }}</span>
                </p>
                <div class="mb-4 flex justify-center">
                  <OTPInput
                    v-model="otpCode"
                    :length="numInputs"
                    :ignorePattern="false"
                    class="style-2 p-0 m-0 list-none"
                    fieldClass="inline-block overflow-hidden align-top"
                    size="32"
                    @change="handleNextStepThree"
                  />
                </div>
                <p
                  class="fs-18 fw-500 txt-grey-700"
                >{{ $t("sign_up.messages_info.not_received_email") }}</p>
                <p
                  class="fs-18 fw-600 txt-pri cursor-pointer"
                  @click="onReSendOtp()"
                  v-if="!time_out"
                >{{ $t("sign_up.messages_info.send_email_again") }}</p>
                <p class="fs-18" v-if="time_out">
                  <span class="fw-500 txt-grey-600">
                    {{
                    $t("sign_up.messages_info.send_email_again")
                    }}
                  </span>
                  <span class="fw-800 txt-grey-900">
                    00:{{
                    time_out && (time_out > 10 ? time_out : "0" + time_out)
                    }}
                  </span>
                </p>
              </div>
            </div>

            <!-- Step 3: Config Organization -->
            <div v-else-if="step === 3" v-loading="isLoading">
              <div class="pt-4 text-center">
                <div class="fs-28 fw-600 txt-black">{{ $t('sign_up.lbl_create_org_and_complete') }}</div>
              </div>
              <div class="w-100-441px py-4">
                <form
                  autocomplete="off"
                  novalidate="novalidate"
                  @submit.prevent="handleSubmitCreateNewOrg"
                >
                  <div class="mb-3">
                    <label class="block mb-0.5 sr-only">{{ $t('sign_up.input_text.org_name') }}</label>
                    <div>
                      <input
                        v-model="formStep3.name"
                        @keyup.enter="handleSubmitCreateNewOrg"
                        :placeholder="
                        `${$t('sign_up.input_text.org_name')}*`
                      "
                        type="text"
                        class="form-control form-input fs-16 fw-500 w-full"
                      />
                    </div>
                    <small
                      v-if="isErrorValid && !!errorFieldsStep3.name"
                      class="text-warning"
                    >{{ errorFieldsStep3.name }}</small>
                  </div>
                  <div class="mb-3">
                    <label class="block mb-0.5 sr-only">{{ $t('form.title.address') }}</label>
                    <div>
                      <input
                        v-model="formStep3.address"
                        @keyup.enter="handleSubmitCreateNewOrg"
                        :placeholder="
                        `${$t('form.title.address')}*`
                      "
                        type="text"
                        class="form-control form-input fs-16 fw-500 w-full"
                      />
                    </div>
                    <small
                      v-if="isErrorValid && !!errorFieldsStep3.address"
                      class="text-warning"
                    >{{ errorFieldsStep3.address }}</small>
                  </div>
                  <div class="mb-3">
                    <label class="block mb-0.5 sr-only">{{ $t('form.title.phone') }}</label>
                    <div>
                      <input
                        v-model="formStep3.phone"
                        @keyup.enter="handleSubmitCreateNewOrg"
                        :placeholder="
                        `${$t('form.title.phone')}*`
                      "
                        type="text"
                        class="form-control form-input fs-16 fw-500 w-full"
                      />
                    </div>
                    <small
                      v-if="isErrorValid && !!errorFieldsStep3.phone"
                      class="text-warning"
                    >{{ errorFieldsStep3.phone }}</small>
                  </div>
                  <div class="mb-3">
                    <label class="block mb-0.5 sr-only">{{ $t('form.title.website') }}</label>
                    <div>
                      <input
                        v-model="formStep3.website"
                        @keyup.enter="handleSubmitCreateNewOrg"
                        :placeholder="
                        $t('form.title.website')
                      "
                        type="text"
                        class="form-control form-input fs-16 fw-500 w-full"
                      />
                    </div>
                    <small
                      v-if="isErrorValid && !!errorFieldsStep3.website"
                      class="text-warning"
                    >{{ errorFieldsStep3.website }}</small>
                  </div>
                  <div class="mb-3">
                    <label class="block mb-0.5 sr-only">{{ $t('form.title.ein_code') }}</label>
                    <div>
                      <input
                        v-model="formStep3.ein"
                        @keyup.enter="handleSubmitCreateNewOrg"
                        :placeholder="
                        `${$t('form.title.ein_code')}*`
                      "
                        type="text"
                        class="form-control form-input fs-16 fw-500 w-full"
                      />
                    </div>
                    <small
                      v-if="isErrorValid && !!errorFieldsStep3.ein"
                      class="text-warning"
                    >{{ errorFieldsStep3.ein }}</small>
                  </div>
                  <div class="mb-2">
                    <button
                      @click="handleSubmitCreateNewOrg"
                      type="button"
                      class="btn bg-pri form-btn w-100 fs-16 fw-600"
                    >{{ $t("multidisciplinary_board.lbl_doc_status_02") }}</button>
                  </div>
                  <div class="text-center py-0.5 mb-3">
                    <span
                      class="text-blue-700 cursor-pointer hover:underline"
                      @click="handleSkip"
                    >{{ $t('button.skip') }}</span>
                  </div>
                </form>
                <div
                  class="fs-13 fw-500"
                  v-html="
                $t('login.other_labels.lbl_term_and_policy', [
                  `<a class='txt-pri' href='#/dieu-khoan' target='_blank'>${$t(
                    'login.other_labels.lbl_term_of_service'
                  )}</a>`,
                  `<a class='txt-pri' href='#/chinh-sach' target='_blank'>${$t(
                    'login.other_labels.lbl_privacy_policy'
                  )}</a>`,
                ])
              "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OTPInput from '@8bu/vue-otp-input'
import { mixinValidationForms } from '../../utils/mixinValidationForms'
import appUtils from '../../utils/appUtils'
import { APP_MODE } from '../../utils/constants'

export default {
  name: 'CreateNewOrg',
  components: { OTPInput },
  mixins: [mixinValidationForms],
  data () {
    return {
      step: 1,
      isLoading: true,
      formStep1: {
        email: ''
      },
      formStep3: {
        name: '',
        address: '',
        phone: '',
        website: '',
        ein: ''
      },
      errorFieldsStep3: {},
      ignoreFieldsStep3: ['website'],
      time_out: 0,
      interval_resend: null,
      otpCode: '',
      otpCodeError: '',
      numInputs: 6,
      warningMsg: ''
    }
  },
  beforeMount () {
    this.handleResetErrors(this.formStep1, this.errorFields, this.ignoreFields)
    this.handleResetErrors(
      this.formStep3,
      this.errorFieldsStep3,
      this.ignoreFieldsStep3
    )
    this.isErrorValid = false
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 750)
  },
  watch: {
    formStep1: {
      handler (data) {
        this.isErrorValid = this.handleValidationForms(
          data,
          this.errorFields,
          this.ignoreFields
        )
      },
      deep: true
    },
    // formStep3: {
    //   handler (data) {
    //     this.isErrorValid = this.handleValidationForms(
    //       data,
    //       this.errorFieldsStep3,
    //       this.ignoreFieldsStep3
    //     )
    //   },
    //   deep: true
    // },
    step (value) {
      switch (value) {
        case 1:
          this.isErrorValid = this.handleResetErrors(
            this.formStep1,
            this.errorFields,
            this.ignoreFields
          )
          break
        case 3:
          this.isErrorValid = this.handleResetErrors(
            this.formStep3,
            this.errorFieldsStep3,
            this.ignoreFieldsStep3
          )
          break

        default:
          break
      }
    }
  },
  methods: {
    handleSkip () {
      appUtils.setAppMode(APP_MODE.organization)
      if (this.$user && this.$user?.members) {
        const { members } = this.$user
        appUtils.setGlobalOrg(members[0]?.org)
      }
      this.$router
        .replace({
          path: '/'
        })
        .catch(_ => {})
    },
    onLogin () {
      this.$router.replace({ name: 'LoginPage' }).catch(_ => {})
    },
    countDownTimeout () {
      if (this.time_out <= 0) {
        clearInterval(this.interval_resend)
        return
      }

      this.time_out--
    },
    async handleNextStepTwo () {
      const self = this
      self.isErrorValid = self.handleResetErrors(
        self.formStep1,
        self.errorFields,
        self.ignoreFields
      )

      try {
        self.isErrorValid = self.handleValidationForms(
          self.formStep1,
          self.errorFields,
          self.ignoreFields
        )

        if (self.isErrorValid) {
          return
        }

        self.isLoading = true

        const params = {
          email_address: self.formStep1.email
        }
        const resp = await self.$rf
          .getRequest('AuthRequest')
          .getOtpCodeWithEmail(params)

        if (!resp.data) {
          self.isLoading = false
          return
        }

        self.warningMsg = resp.data?.Message
        self.isLoading = false
        self.step = 2
        self.time_out = 30
        self.interval_resend = setInterval(self.countDownTimeout, 1000)
      } catch (error) {
        console.log(error)
        self.isLoading = false
      } finally {
        // self.isLoading = false
      }
    },
    async onReSendOtp () {
      const self = this

      self.isLoading = true

      const params = {
        email_address: self.formStep1.email
      }
      const resp = await self.$rf
        .getRequest('AuthRequest')
        .getOtpCodeWithEmail(params)

      if (!resp.data) {
        self.isLoading = false
        return
      }

      self.$message({
        type: 'success',
        message: self.$t('sign_up.lbl_re_send_otp_code')
      })
      self.isLoading = false
      self.time_out = 30
      self.interval_resend = setInterval(self.countDownTimeout, 1000)
    },
    async handleNextStepThree () {
      if (!this.otpCode.length || this.otpCode.length < this.numInputs) {
        return
      }

      try {
        this.isLoading = true
        const params = {
          validate_code: this.otpCode,
          email_address: this.formStep1.email
        }
        const resp = await this.$rf
          .getRequest('AuthRequest')
          .confirmOtpCodeInRegOrg(params)

        if (!resp.data) {
          this.isLoading = false
          return
        }

        if (resp.data?.error) {
          this.otpCodeError = resp.data?.error.code
          this.isLoading = false
          return
        }

        const { token } = resp.data
        await this.loginWithToken(token)

        this.isLoading = false
        this.step = 3
      } catch (error) {
        console.log(error)
        this.isLoading = false
      } finally {
        // this.isLoading = false
      }
    },
    async handleSubmitCreateNewOrg () {
      const self = this
      self.isErrorValid = self.handleResetErrors(
        self.formStep3,
        self.errorFieldsStep3,
        self.ignoreFieldsStep3
      )

      try {
        self.isErrorValid = self.handleValidationForms(
          self.formStep3,
          self.errorFieldsStep3,
          self.ignoreFieldsStep3
        )

        if (self.isErrorValid) {
          return
        }

        if (!self.$user) {
          self.$message({
            type: 'warning',
            message: self.$t('Không tìm thấy thông tin người dùng')
          })
          return
        }

        self.isLoading = true

        const params = {
          ...self.formStep3
        }
        const resp = await self.$rf
          .getRequest('DoctorRequest')
          .createNewOrganization(params)

        if (!resp.data) return

        this.$appMode = APP_MODE.organization
        appUtils.setAppMode(APP_MODE.organization)
        appUtils.setGlobalOrg(resp.data?.Org)
        self.$message({
          type: 'success',
          message: self.$t('Bạn đã tạo thành công Tổ chức')
        })

        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
        // self.$router
        //   .replace({
        //     path: '/'
        //   })
        //   .catch(_ => {})

        self.isLoading = false
      } catch (error) {
        console.log(error)
        self.isLoading = false
      } finally {
        // self.isLoading = false
      }
    },
    async loginWithToken (token) {
      if (token) {
        appUtils.setLocalToken(token)
        await this.setLocalUserInfo()
      }
    },
    async setLocalUserInfo () {
      let resp = await this.$rf.getRequest('AuthRequest').me()
      if (resp) {
        appUtils.setLocalUser(resp.data)
      }
      return resp
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .overflow-hidden {
    overflow: hidden;
  }
  .y100-auto {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .sign-form-wraper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .form-input {
    color: #1d2939;
    border: 0.1px solid #98a2b3;
    padding: 8px;
    border-radius: 12px;
  }
  .form-btn {
    color: white;
    border-radius: 12px;
    padding: 12px;
  }
  .form-btn:disabled {
    background-color: #667085;
  }
  .txt-msg {
    color: #98a2b3;
  }
  .txt-success {
    color: #027a48;
  }
  .bg-img-login {
    height: 100vh;
    background-image: url("../../../public/assets/images/login/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .w-100-678px {
    width: 100%;
    max-width: 678px;
    margin: 0 auto;
  }
  .w-100-441px {
    width: 100%;
    max-width: 441px;
    margin: 0 auto;
  }
  .h-100vh {
    height: 100vh;
  }
  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .code {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 25px;
    margin: 0px 5px;
    border: 0px;
    border-bottom: 1px solid black;
    &::placeholder {
      color: #f5f5f5;
    }
    &::-moz-placeholder {
      color: #f5f5f5;
    }
    &::-webkit-input-placeholder {
      color: #f5f5f5;
    }
  }
  .code:focus,
  .code:focus-visible {
    outline: 0px;
    color: #ef3a3a;
    border-color: #ef3a3a !important;
  }

  .otp-input-8 {
    display: block;
    line-height: 1;
    padding-left: 0;
    margin: 0;
  }

  .otp-input-8-field {
    display: inline-block;
    vertical-align: top;
    font-size: inherit;
    overflow: hidden;
  }

  .otp-input-8-field input {
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    width: 1.75em;
    height: 1.75em;
    font-size: inherit;
    line-height: 1.75em;
    text-align: center;
    outline: none;
  }

  .style-2 {
    .otp-input-8-field {
      position: relative;
      &::after {
        content: "";
        border-radius: 9999px;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        display: block;
        height: 4px;
        background-color: #000;
      }
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      input {
        background: transparent;
        color: #000;
        font-weight: bold;
      }
    }
  }
}
</style>
